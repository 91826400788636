import React from "react";
import { Link } from "react-router-dom";
function MobileMenu(props) {
  return (
    <section
      className={props.onTrigger ? "mob-nav-menu active" : "mob-nav-menu "}
    >
      <nav className="w-full">
        <div className="relative bg-teal dark:bg-gray-800 ">
          <div className="button pl-4 pt-3 primary-white ">
            <Link to="#" onClick={props.onHandle}>
              <img
                src="/images/menu/vectormenu-cross.svg"
                alt="menucross"
                height="40px"
                width="40px"
                className="left"
              />
            </Link>
          </div>
        </div>
        <div className="flex flex-col w-screen h-screen px-4 py-0 bg-teal border-r dark:bg-gray-800 dark:border-gray-600">
          <div className="flex flex-col justify-between flex-1 mt-6">
            <nav>
              <Link
                className="flex items-center px-4 py-2 mt-5 primary-white transition-colors duration-200 transform rounded-md dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-gray-200 hover:text-gray-700"
                to="/home"
                onClick={props.onHandle}
              >
                <span className="mx-4 font-medium uppercase">home</span>
              </Link>
              <span className="flex items-center px-4 py-2 mt-5 mx-4 font-medium uppercase primary-white transition-colors duration-200 transform rounded-md dark:text-gray-400 disabled:">
                Our group companies
              </span>
              <hr className="my-0 dark:border-gray-600" />
              <Link
                className="flex items-center ml-9 py-2 mt-2 primary-white text-xs transition-colors duration-200 transform rounded-md dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-gray-200 hover:text-gray-700"
                to="/textile"
                onClick={props.onHandle}
              >
                <span className="mx-4 font-medium ">Kohinoor Textiles</span>
              </Link>
              <Link
                className="flex items-center ml-9 py-2 mt-2 primary-white text-xs transition-colors duration-200 transform rounded-md dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-gray-200 hover:text-gray-700"
                to="Knittex"
                onClick={props.onHandle}
              >
                <span className="mx-4 font-medium ">Kohinoor Knittex</span>
              </Link>
              <Link
                className="flex items-center ml-9 py-2 mt-2 primary-white text-xs transition-colors duration-200 transform rounded-md dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-gray-200 hover:text-gray-700"
                to="/Processing"
                onClick={props.onHandle}
              >
                <span className="mx-4 font-medium ">Kohinoor Processing</span>
              </Link>
              <Link
                className="flex items-center ml-9 py-2 mt-2 primary-white text-xs transition-colors duration-200 transform rounded-md dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-gray-200 hover:text-gray-700"
                to="/exportimport"
                onClick={props.onHandle}
              >
                <span className="mx-4 font-medium ">
                  Kohinoor Export/Import
                </span>
              </Link>

              <Link
                className="flex items-center px-4 py-2 mt-5 primary-white transition-colors duration-200 transform rounded-md dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-gray-200 hover:text-gray-700"
                to="/quality"
                onClick={props.onHandle}
              >
                <span className="mx-4 font-medium uppercase">quality</span>
              </Link>

              <Link
                className="flex items-center px-4 py-2 mt-5 primary-white transition-colors duration-200 transform rounded-md dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-gray-200 hover:text-gray-700"
                to="/csr"
                onClick={props.onHandle}
              >
                <span className="mx-4 font-medium uppercase">csr</span>
              </Link>

              <Link
                className="flex items-center px-4 py-2 mt-5 primary-white transition-colors duration-200 transform rounded-md dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-gray-200 hover:text-gray-700"
                to="/complaince"
                onClick={props.onHandle}
              >
                <span className="mx-4 font-medium uppercase">compliance</span>
              </Link>

              <Link
                className="flex items-center px-4 py-2 mt-5 primary-white transition-colors duration-200 transform rounded-md dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-gray-200 hover:text-gray-700"
                to="/product"
                onClick={props.onHandle}
              >
                <span className="mx-4 font-medium uppercase">
                  product and technology
                </span>
              </Link>
              <Link
                className="flex items-center px-4 py-2 mt-5 primary-white transition-colors duration-200 transform rounded-md dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-gray-200 hover:text-gray-700"
                to="/team"
                onClick={props.onHandle}
              >
                <span className="mx-4 font-medium uppercase">our team</span>
              </Link>
              <Link
                className="flex items-center px-4 py-2 mt-5 primary-white active:bg-green-700 rounded-md dark:bg-gray-700 dark:text-gray-200"
                to="/about"
                onClick={props.onHandle}
              >
                <span className="mx-4 font-medium uppercase">About Us</span>
              </Link>
              <Link
                to="/contact"
                onClick={props.onHandle}
                className="flex items-center px-4 py-2 mt-5 primary-white active:bg-green-700 rounded-md dark:bg-gray-700 dark:text-gray-200"
              >
                <span className="mx-4 font-medium uppercase">contact us</span>
              </Link>
            </nav>
          </div>
        </div>
      </nav>
    </section>
  );
}

export default MobileMenu;
