const SideBar = (props) => {
    return (
        <>
            <div className="side-sticky-bar md:block hidden z-10">
                <div className="side-text font-semibold text-center primary-white z-10">
                    {props.title}
                </div>
                <div className="ellipse">
                    <object
                        data="https://kohinoortextile.com/static/media/images/arrow.svg"
                        type="image/svg+xml"
                        aria-labelledby="menu"
                        height="18"
                        width="27px"
                        className="side-arrow"
                    ></object>
                </div>
            </div>
        </>
    );
};

export default SideBar;
