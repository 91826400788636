import "./style.css";
import DescriptionBox from "./DescriptionBox";
import SideBar from "./SideBar";
import { useEffect, useState } from "react";
import axios from "axios";
import NavBar from "./NavBar";
import Footer from "./Footer";

const GroupCompanies = () => {
  const [response, setResponse] = useState("");
  useEffect(() => {
    axios
      .get("https://kohinoortextile.com/api/csr/")
      .then((res) => {
        setResponse(res.data[0]);
      })
      .catch((error) => {
        console.log("the error ", error.response);
      });
  }, []);

  return (
    <>
      <NavBar />
      <section className="md:max-w-7xl w-full md:mx-auto mx-0 md:pb-16 pb-12">
        <SideBar title={response?.title} />
        <div className="">
          <img
            src={response?.image}
            alt=""
            width="100%"
            className="lg:h-auto md:h-72 h-44"
          />
        </div>
        {response?.descriptions?.map((desc) => (
          <DescriptionBox
            title={desc.desc_title}
            color="font-teal"
            size="md:text-2xl text-xl lg:pl-0 md:pl-10"
            description={desc.desc_text}
          />
        ))}
      </section>
      <Footer />
    </>
  );
};

export default GroupCompanies;
