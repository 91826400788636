import DescriptionBox from "./DescriptionBox";
import SideBar from "./SideBar";
import axios from "axios";
import { useEffect, useState } from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
const AboutUs = () => {
    const [about, setAbout] = useState("");
    useEffect(() => {
        axios
            .get("https://kohinoortextile.com/api/aboutus/about-us/")
            .then((res) => {
                setAbout(res.data);
            });
        // eslint-disable-next-line
    }, []);
    return (
        <>
            <NavBar />
            <section className="max-w-7xl mx-auto ">
                <SideBar title={about?.title} />
                <div className="">
                    <img
                        src={about?.image}
                        alt=""
                        width="100%"
                        className="lg:h-auto md:h-72 h-44"
                    />
                </div>
                <div className="md:max-w-6xl md:pb-10 pb-12 max-w-full">
                    {about?.descriptions?.length > 0 &&
                        about?.descriptions.map((desc, index) =>
                            desc?.desc_title === "About Us" ? (
                                <DescriptionBox
                                    key={index}
                                    title={desc?.desc_title}
                                    color=" font-teal "
                                    size="text-2xl"
                                    description={desc?.desc_text}
                                />
                            ) : (
                                <DescriptionBox
                                    key={index}
                                    size="text-xl font-semibold"
                                    title={desc?.desc_title}
                                    description={desc?.desc_text}
                                />
                            )
                        )}
                </div>
                <div className="flex md:space-x-4 md:pb-16 md:flex-row flex-col md:space-y-0 space-y-4 pb-12 md:px-0 px-2 relative">
                    {about?.branches?.length > 0 &&
                        about?.branches.map((img, index) => (
                            <div key={index} className="relative">
                                <div className="branch-location">
                                    <h1>{img.branch_name}</h1>
                                </div>
                                <img
                                    src={img.image}
                                    alt=""
                                    className="h-full"
                                />
                            </div>
                        ))}
                </div>
            </section>
            <Footer />
        </>
    );
};

export default AboutUs;
