import "./style.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import PhotoSection from "./PhotoSection";

const Homepage = () => {
  const [response, setResponse] = useState("");
  useEffect(() => {
    axios
      .get("https://kohinoortextile.com/api/team/")
      .then((res) => {
        setResponse(res.data);
      })
      .catch((error) => {
        console.log("the error ", error.response);
      });
  }, []);
  return (
    <>
      <NavBar />
      <section
        style={{
          backgroundImage: `url(/images/homebg.png)`,
          backgroundSize: "cover",
          height: "600px",
        }}
        className="relative h-96 md:h-0 "
      >
        <div
          className="2xl:left-1/3 md:max-w-xl md:text-4xl text-white uppercase font-bold relative md:top-36 md:left-24 max-w-xs text-xl left-10 top-48"
          style={{ backgroundImage: `url(/images/rectang.svg)` }}
        >
          <div className="md:px-10 md:py-12 px-8 py-10 text-center md:text-left">
            <h1 className="pb-2">WE MAKE</h1>
            <h1 className="pb-6">authentic fabrics.</h1>
            <Link to="/contact">
              <button className="px-16 py-3 contact-btn bg-white text-black text-xl font-normal hover:bg-gray-100">
                Contact Us
              </button>
            </Link>
          </div>
        </div>
      </section>
      {/* about us section  */}
      <section className="flex lg:max-w-7xl mx-auto justify-between md:max-w-full w-full">
        <div className="flex pt-10 md:pt-24 md:pb-24 pb-16 md:flex-row flex-col">
          <div className="md:w-1/2 capitalize font-bold lg:text-3xl primary-black md:text-xl w-full pb-4 md:pb-0 text-left md:text-center px-5 md:px-5">
            About us
          </div>
          <div className="md:w-1/2 text-left  w-full md:px-0 px-5 ">
            <p className="primary-black pb-8">
              The definition of textile is any material made of interlacing
              fibers, including carpet and geotextiles. Any woven or knitted
              fabric is a textile. What every textile has in common is that it's
              made from textile fiber. The definition of textile is any material
              made of interlacing fibers, including carpet and geotextiles. Any
              woven or knitted fabric is a textile. What every textile has in
              common is that it's made from textile .or knitted fabric is a
              textile. What every textile has in common is that it's made from
              textile.
            </p>
            <Link to="/about">
              <button className="font-semibold btn-primary text-white px-16 py-3 ">
                Know More...
              </button>{" "}
            </Link>
          </div>
        </div>
      </section>
      {/* group companies section  */}
      <section className="w-full md:pb-24 pb-16 mx-auto flex justify-between lg:space-x-8 lg:flex-row flex-col md:space-x-0">
        <div className="lg:w-1/4 back-teal md:w-full">
          <div>
            <h1 className="text-white lg:text-left px-12 font-bold text-2xl capitalize primary-white pt-16 pb-10 md:text-center">
              Our group companies
            </h1>
            <div className="lg:text-left lg:pt-12 md:text-center md:pt-6 pb-6 md:pb-0">
              <ul className="px-8  text-xl secondary-white hovering">
                <Link to="/textile">
                  <li className="md:p-4 p-2">Kohinoor Textile</li>
                </Link>
                <Link to="/knittex">
                  <li className="md:p-4 p-2">Kohinoor Knittex</li>
                </Link>
                <Link to="/processing">
                  <li className="md:p-4 p-2">Kohinoor Processing</li>
                </Link>
                <Link to="/exportimport">
                  <li className="md:p-4 p-2">Kohinoor Export/Import</li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
        <div className="lg:w-3/4 md:w-full text-left space-y-4 lg:pr-32 ">
          <img
            src="/images/fabrics.png"
            alt="fabrics"
            className=""
            width="100%"
          />
          <div className="lg:px-0 px-5 md:px-0">
            <p>
              We make fabrics for all kind of clothes. The manufacturing of the
              fabrics are done with different technologies . Modern machines and
              technologies are used in out industry hence making out fabrics of
              the best quality possible.We make fabrics for all kind of clothes.
              The manufacturing of the fabrics are done with different
              technologies . Modern machines and technologies are used in out
              industry hence making out fabrics of the best quality possible.
            </p>
            <p>
              We make fabrics for all kind of clothes. The manufacturing of the
              fabrics are done with different technologies . Modern machines and
              technologies are used in out industry hence making out fabrics of
              the best quality possible. Threads are also of high quality. d
              technologies are used in out industry hence making out fabrics of
              the best quality possible. Threads are also of high quality.
            </p>
          </div>
          <div className="flex items-center py-3">
            <button className="btn-primary px-16 py-3 capitalize mx-auto ">
              <Link to="/textile"> go to kohinoor textile </Link>
            </button>
          </div>
        </div>
      </section>
      {/* qualities we provide section  */}
      <section className="lg:max-w-7xl flex  mx-auto  max-w-full lg:flex-row flex-col md:pb-12 pb-16">
        <div className="lg:w-1/4">
          <h1 className="capitalize font-bold lg:text-3xl primary-black md:text-xl pb-4 lg:pb-0 text-left md:text-center px-5 md:px-5">
            qualities we provide
          </h1>
        </div>
        <div className="lg:w-3/4 ">
          <div className=" lg:pl-52 lg:pr-20 md:pl-5 md:pr-5 space-y-6  px-5 text-center">
            <p className=" primary-black text-left">
              The definition of textile is any material made of interlacing
              fibers, including carpet and geotextiles. Any woven or knitted
              fabric is a textile. What every textile has in common is that it's
              made from textile fiber. The definition of textile is any material
              made of interlacing fibers, including carpet and geotextiles. Any
              woven or knitted fabric is a textile. What every textile has in
              common is that it's made from textile .oven or knitted fabric is a
              textile. What every textile has in common is that it's made from t
            </p>
            <button className="btn-primary px-16 py-3 capitalize">
              <Link to="/quality">see all qualities</Link>
            </button>
          </div>
        </div>
      </section>
      {/* clothes images section */}
      <PhotoSection />
      {/* top management   */}

      <section className="lg:max-w-7xl md:pb-24 pb-16  mx-auto  space-y-7 md:max-w-full">
        <h1 className="capitalize font-bold lg:text-3xl primary-black lg:text-left md:text-xl md:text-center text-left px-2 md:px-0">
          Top Management
        </h1>
        {response &&
          response?.map((res) => {
            return (
              <>
                {res?.level.toLowerCase() === "top management" &&
                res?.staffs.length > 0
                  ? res?.staffs?.map((staff) => {
                      return (
                        <div
                          className="flex justify-between  lg:px-0 md:px-5 md:flex-row flex-col px-2 "
                          key={staff?.id}
                        >
                          <div className="md:w-1/2 w-full">
                            <img
                              src={staff?.image}
                              alt=""
                              className="w-full md:w-11/12"
                            />
                          </div>
                          <div className="md:w-1/2 w-full md:text-left text-center">
                            <h1 className="capitalize font-bold pb-0 pt-2 md:pt-0 md:pb-1 lg:text-2xl md:text-xl text-lg">
                              {staff?.name}
                            </h1>
                            <p className="capitalize md:pb-3 pb-3 lg:text-lg md:text-base text-sm">
                              {" "}
                              {staff?.post}
                            </p>
                            <p className="text-left">{staff?.description}</p>
                          </div>
                        </div>
                      );
                    })
                  : ""}
              </>
            );
          })}
      </section>
      <Footer />
    </>
  );
};

export default Homepage;
