const DescriptionBox = (props) => {
  return (
    <>
      <div className="md:max-w-7xl max-w-full">
        <div className="flex mx-auto md:pt-16 pt-12 md:flex-row flex-col">
          <div className="left md:w-1/2 w-full">
            <h1
              className={`font-bold ${props.color} ${props.size}  primary-black capitalize md:pb-0 pb-3 px-2 md:px-0 text-left md:text-center`}
            >
              {props.title}
            </h1>
          </div>
          <div className="content-right  md:w-1/2 w-full text-left  primary-black md:px-0 px-2 ">
            <p>{props.description}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DescriptionBox;
