import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";
import { SiTwitter, SiInstagram, SiFacebook } from "react-icons/si";
import { FiPhoneCall } from "react-icons/fi";
import { FaArrowRight } from "react-icons/fa";
import "./style.css";
const LandingPage = () => {
    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);

    const [BWidth, setBWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setBWidth(window.innerWidth);
        });
        // eslint-disable-next-line
    }, [window.innerWidth]);
    return (
        <>
            <section
                className="bg-gray-500 lg:h-screen w-screen h-full"
                style={{
                    backgroundImage:
                        "url(https://kohinoortextile.com/static/media/images/background.png)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                }}
            >
                <nav className="flex justify-evenly py-10 ">
                    <div>
                        <img
                            src="https://kohinoortextile.com/static/media/images/logo.png"
                            alt=""
                            className="h-86"
                        />
                    </div>
                    <div className="flex justify-end w-1/3">
                        <div className="text-xl text-white">
                            <Link to="#" onClick={showSidebar}>
                                {" "}
                                <img
                                    src="https://kohinoortextile.com/static/media/images/Vector.svg"
                                    className="inline"
                                    alt=""
                                />{" "}
                                Menu{" "}
                            </Link>
                        </div>
                    </div>
                </nav>
                <header className="flex flex-col lg:mt-12 mt-24 lg:flex-row lg:justify-evenly xl:flex-row xl:justify-evenly xl:mt-24">
                    <div className="text-center mx-10 xl:mx-0 xl:w-1/3 lg:w-2/4">
                        <h1 className="text-xl font-semibold text-white my-5 ">
                            Since 1998
                        </h1>

                        <div className="relative text-gray-600 focus-within:text-gray-400 xl:my-5 my-0">
                            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                <button
                                    type="submit"
                                    className="p-1 focus:outline-none focus:shadow-outline"
                                >
                                    <svg
                                        fill="none"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        className="w-6 h-6"
                                    >
                                        <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                                    </svg>
                                </button>
                            </span>
                            <input
                                type="search"
                                name="q"
                                className="py-2 text-sm text-gray-900 bg-white w-full h-16 lg:h-12 xl:h-16 rounded-full pl-12 focus:outline-none focus:bg-white focus:text-gray-900"
                                placeholder="Search..."
                                autoComplete="off"
                            />
                        </div>
                        <button className="my-6 py-3 px-10 rounded-full btn-primary landing-contact">
                            <Link to="/contact">
                                <span className="text-white ">Contact Us</span>
                            </Link>
                        </button>
                        <h2 className=" underline text-2xl xl:text-3xl xl:py-6 py-0 home-link">
                            <Link to="/home">
                                Continue to Website{" "}
                                <FaArrowRight className="inline-block" />
                            </Link>
                        </h2>

                        <p className="text-white  text-base lg:py-4 py-8">
                            <FiPhoneCall className="inline-block" />
                            <span className="pl-2">+977-9841111110</span>
                        </p>
                    </div>
                    <div className="font-sans lg:text-3xl md:text-2xl text-xl text-white flex justify-center py-16 xl:w-1/3 xl:justify-end  ">
                        <div className="space-y-4">
                            <h1 className=" text-5xl font-extrabold">
                                Fabrics
                            </h1>
                            <h2 className="text-3xl">We Make.</h2>
                            <h2>We Provide.</h2>
                            <h2>We Process.</h2>
                            <h2>We Export.</h2>
                        </div>
                    </div>
                </header>

                <footer className="flex flex-col lg:h-12 justify-between w-screen lg:absolute  lg:bottom-0 lg:flex-row xl:h-12">
                    <div className="left-footer flex justify-evenly pt-2 lg:w-1/4 w-64 mx-auto lg:mx-0 md:pb-2 lg:pb-0 pb-4">
                        <div>
                            <SiInstagram className="w-6 h-6 lg:w-8 lg:h-8" />
                        </div>
                        <div>
                            <SiTwitter className="w-6 h-6 lg:w-8 lg:h-8" />
                        </div>
                        <div>
                            <SiFacebook className=" w-6 h-6 lg:w-8 lg:h-8" />
                        </div>
                    </div>
                    <div className="right-footer uppercase lg:w-3/4 flex justify-center lg:justify-end items-center lg:pb-1 flex-row xl:pb-0 space-x-2 lg:pr-4 md:text-sm text-xs w-full md:pr-0 md:pb-1 md:justify-center">
                        <h2>copyright kohinoor 2021</h2>
                        <h2 className="border-l-2 border-color px-2">
                            all rights reserved
                        </h2>
                    </div>
                </footer>
            </section>

            {BWidth >= 600 ? (
                <DesktopMenu onHandle={showSidebar} onTrigger={sidebar} />
            ) : (
                <MobileMenu onHandle={showSidebar} onTrigger={sidebar} />
            )}
        </>
    );
};

export default LandingPage;
