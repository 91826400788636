import DescriptionBox from "./DescriptionBox";
import Footer from "./Footer";
import NavBar from "./NavBar";
import SideBar from "./SideBar";
import "./style.css";
const GroupCompanies = (props) => {
  return (
    <>
      <NavBar />
      <section className="md:max-w-7xl w-full md:mx-auto mx-0 md:pb-16 pb-12">
        <SideBar title={props.heading} />
        <div className="">
          <img
            src={props.bgImage}
            alt=""
            width="100%"
            className="lg:h-auto md:h-72 h-44"
          />
        </div>

        <DescriptionBox
          title={props.heading}
          color="font-teal"
          size={"md:text-2xl text-xl"}
          description={props.description}
        />
        <DescriptionBox
          title="Overview"
          size="text-2xl font-bold"
          description={props.overview}
        />
        <div>
          <div className="textile  flex mx-auto md:flex-row flex-col md:pt-16 pt-12 md:px-0 px-3">
            <div className="left md:w-1/2 w-full ">
              <h1 className="font-bold text-2xl  primary-black capitalize">
                products
              </h1>
            </div>
            <div className="content-right  md:w-1/2 w-full  md:text-left  ">
              <div className="flex flex-nowrap md:flex-wrap md:space-x-0 md:flex-row flex-col space-x-0 md:space-y-0 space-y-2">
                {props.products?.length > 0 &&
                  props.products?.map((imge, index) => (
                    <div
                      className=" w-full pr-0 pb-0 md:w-1/3 lg:w-1/4 md:space-x-0 md:pb-2  md:pr-2"
                      key={index}
                    >
                      <img
                        src={imge.product_image}
                        alt=""
                        className="md:max-h-40 md:w-full h-64 w-full"
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default GroupCompanies;
