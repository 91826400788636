import DescriptionBox from "./DescriptionBox";
import SideBar from "./SideBar";
import axios from "axios";
import { useEffect, useState } from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";

const OurTeam = () => {
  // eslint-disable-next-line
  const [response, setResponse] = useState("");
  useEffect(() => {
    axios
      .get("https://kohinoortextile.com/api/team/")
      .then((res) => {
        setResponse(res.data);
      })
      .catch((error) => {
        console.log("the error ", error.response);
      });
  }, []);

  return (
    <>
      <NavBar />
      <section className="max-w-7xl mx-auto ">
        <SideBar title="Our Team" />
        <div className="">
          <img src="https://kohinoortextile.com/static/media/images/ourteam.png" alt="" width="100%" />
        </div>
        <div className="max-w-6xl pb-10">
          <DescriptionBox
            title="Our team"
            color=" font-teal "
            size="md:text-2xl text-xl"
            description="We make fabrics for all kind of clothes. The manufacturing of the fabrics are done with different technologies . Modern machines and technologies are used in out industry hence making out fabrics of the best quality possible. We make fabrics for all kind of clothes. The manufacturing of the fabrics are done with different technologies . Modern machines and technologies are used in out industry hence making "
          />
        </div>
        <div className="lg:h-40  md:h-32 h-28 bg-teal flex justify-around items-center">
          <div>
            <h1 className="primary-white lg:text-4xl md:text-3xl text-2xl font-bold">
              21
            </h1>
            <p className="primary-white lg:text-xl md:text-lg text-xs pt-2">
              Managerial Staff
            </p>
          </div>
          <div>
            <h1 className="primary-white lg:text-4xl md:text-3xl text-2xl font-bold">
              100
            </h1>
            <p className="primary-white lg:text-xl md:text-lg text-xs pt-2">
              Technical Staff
            </p>
          </div>
          <div>
            <h1 className="primary-white lg:text-4xl md:text-3xl text-2xl font-bold">
              4
            </h1>
            <p className="primary-white lg:text-xl md:text-lg text-xs pt-2">
              Upper Management
            </p>
          </div>
        </div>
        <div className="max-w-6xl pb-10">
          {response &&
            response?.map((res) => {
              return (
                <>
                  {" "}
                  <DescriptionBox
                    title={res?.level}
                    color="font-teal text-2xl"
                    description={res?.description}
                  />
                  <div>
                    {res?.staffs.length > 0
                      ? res?.staffs?.map((staff) => {
                          return (
                            <div
                              key={staff?.id}
                              className="textile flex mx-auto md:pt-16 pt-12 md:flex-row flex-col md:px-0 px-2"
                            >
                              <div className="left md:w-1/2 w-full md:pb-0 pb-3">
                                <h1
                                  className={`font-bold  primary-black capitalize`}
                                >
                                  {staff?.name}
                                </h1>
                                <p>{staff?.post}</p>
                              </div>
                              <div className="content-right  md:w-1/2 w-full text-left  primary-black md:px-0 px-2 bg-gray-50">
                                <img
                                  src={staff?.image}
                                  className="staff-photo"
                                  alt=""
                                />
                                <p className="pt-1 md:pt-2 lg:pt-4 overflow-hidden">
                                  {staff?.description}
                                </p>
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </>
              );
            })}
        </div>
        <div className="flex md:pt-12 md:pb-16 md:max-w-7xl mx-auto flex-nowrap md:flex-wrap space-x-0 w-full md:flex-row flex-col pb-12 md:space-y-0 space-y-3 md:px-0 px-3">
          {response &&
            response?.map((res) =>
              res?.staffs.length > 0
                ? res?.staffs?.map((staff) => (
                    <div className="w-full pr-0 pb-0 md:w-1/4 md:pr-4 md:pb-4">
                      <img src={staff?.image} alt="" className="image-staff" />
                      <div>
                        <h1
                          className={`font-bold pt-3  primary-black capitalize`}
                        >
                          {staff?.name}
                        </h1>
                        <p>{staff?.post}</p>
                      </div>
                    </div>
                  ))
                : ""
            )}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default OurTeam;
