import React, { useState, useEffect } from "react";

import "./style.css";
import { Link } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";
const NavBar = () => {
    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);

    const [BWidth, setBWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setBWidth(window.innerWidth);
        });
        // eslint-disable-next-line
    }, [window.innerWidth]);

    return (
        <>
            <div
                className={" navbar bg-primary-white  sticky top-0 z-50 w-full"}
            >
                <div className="flex justify-between py-3 lg:max-w-7xl max-w-full mx-auto">
                    <div>
                        <Link to="/home">
                            <img
                                src="https://kohinoortextile.com/static/media/images/logo-black.svg"
                                alt=""
                                className="lg:h-12 lg:w-52 lg:pl-0 md:h-10 md:w-40 md:pl-4 h-8 w-36"
                            />
                        </Link>
                    </div>
                    <div className="flex justify-end lg:w-1/3  w-1/2 lg:pr-2 md:pr-5 pr-3">
                        <div className="text-xl text-black flex items-center">
                            <Link to="#" onClick={showSidebar}>
                                <AiOutlineMenu className="md:h-7 md:w-7 md:pr-1 inline-block h-8 w-12" />
                                <span className="md:inline hidden">Menu</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            {BWidth >= 600 ? (
                <DesktopMenu onHandle={showSidebar} onTrigger={sidebar} />
            ) : (
                <MobileMenu onHandle={showSidebar} onTrigger={sidebar} />
            )}
        </>
    );
};

export default NavBar;
