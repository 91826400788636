import { Link } from "react-router-dom";
import { IoCallOutline } from "react-icons/io5";
import { AiOutlineMail } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
const Footer = () => {
  return (
    <>
      <footer style={{ background: "#007E7B", color: "#E2E2E2" }}>
        <div className="lg:max-w-7xl flex mx-auto md:pb-16 pb-8 max-w-full md:flex-row flex-col">
          <div className="left-footer md:w-1/2 w-full md:text-left space-y-2 text-left text-sm lg:text-base lg:px-3 md:px-5">
            <div className="lg:pt-8 md:pb-2 md:pt-4 pb-0 pt-3">
              <Link to="/home">
                <img
                  src="./images/logo.png"
                  alt=""
                  className="lg:h-16 lg:w-48 md:h-12 md:w-36 h-10 w-32 mx-auto md:mx-0"
                />
              </Link>
            </div>
            <div>
              <p className="md:pb-6 pb-4 lg:pt-2 pt-0 lg:mr-16 mr-0 text-left px-5 md:px-0">
                Kohinoor makes authentic fabric with best quality of threads and
                best quality of fabrics available. We provide 4 services of
                textile, knittex, processing and import/export
              </p>
            </div>

            <div className="flex py-1 md:pl-0 pl-5">
              <IoCallOutline className="w-5 h-5 " />
              <span className="pl-1"> +977-9841111110</span>
            </div>
            <div className="flex md:pl-0 pl-5">
              <GoLocation className="w-5 h-5" />
              <span className="pl-1">Indrachowk, Kathmandu, Nepal</span>
            </div>
            <div className="flex py-1 md:pl-0 pl-5">
              <AiOutlineMail className="w-6 h-5 " />
              <span className="pl-1">kohinoor@textile.com</span>
            </div>
          </div>
          <div className="right-footer lg:pt-24 pt-0 w-1/2  grid-cols-4 text-left text-xs md:text-sm lg:text-base hidden md:grid">
            <div className="lg:mx-auto md:mx-0">
              <h1 className="py-6 font-semibold" style={{ color: "#FAFAFA" }}>
                Group Companies
              </h1>
              <ul className="space-y-3">
                <li className="lg:pb-3 md:pb-2">
                  <Link to="/textile">Kohinoor Textile </Link>
                </li>
                <Link to="/knittex">
                  <li className="lg:pb-3 md:pb-2">Kohinoor Knittex</li>
                </Link>
                <Link to="/processing">
                  <li className="lg:pb-3 md:pb-2">Kohinoor Processing</li>
                </Link>
                <Link to="/exportimport">
                  <li className="lg:pb-3 md:pb-2">Kohinoor Import/Export</li>
                </Link>
              </ul>
            </div>
            <div className="mx-auto">
              <h1 className="py-6 font-semibold" style={{ color: "#FAFAFA" }}>
                Quality
              </h1>
              <ul className="space-y-2 ">
                <Link to="quality">
                  <li className="lg:pb-3 md:pb-2">Shirting</li>
                </Link>
                <Link to="quality">
                  <li className="lg:pb-3 md:pb-2">Suiting</li>
                </Link>
                <Link to="quality">
                  <li className="lg:pb-3 md:pb-2">Cotton</li>
                </Link>
                <Link to="quality">
                  <li className="lg:pb-3 md:pb-2">Net</li>
                </Link>
                <Link to="quality">
                  <li className="lg:pb-3 md:pb-2">Knitting</li>
                </Link>
              </ul>
            </div>
            <div className="mx-auto">
              <h1 className="py-6 font-semibold" style={{ color: "#FAFAFA" }}>
                Certifications
              </h1>
              <ul className="space-y-2 ">
                <Link to="/complaince">
                  <li className="lg:pb-3 md:pb-2">Complaiance</li>
                </Link>
                <Link to="/csr">
                  <li className="lg:pb-3 md:pb-2">CSR</li>
                </Link>
              </ul>
            </div>
            <div className="mx-auto">
              <h1 className="py-6 font-semibold" style={{ color: "#FAFAFA" }}>
                Reach Us
              </h1>
              <ul className="space-y-2 ">
                <Link to="/contact">
                  <li className="lg:pb-3 md:pb-2">Contact Us </li>
                </Link>
                <Link to="/">
                  <li className="lg:pb-3 md:pb-2">Franchise</li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright-section " style={{ background: "#FAFAFA" }}>
          <div
            className="lg:max-w-7xl flex mx-auto  max-w-full justify-center flex-col md:flex-row"
            style={{ background: "#FAFAFA" }}
          >
            <h1
              className="py-1 capitalize primary-white lg:text-base md:text-sm text-xs"
              style={{ color: "#AAAAAA" }}
            >
              Copyright | 2021 Kohinoor. All Rights Reserved
            </h1>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
