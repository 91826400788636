import DescriptionBox from "./DescriptionBox";
import SideBar from "./SideBar";
import "./style.css";
import { IoCallOutline } from "react-icons/io5";
import { AiOutlineMail } from "react-icons/ai";
import { useFormik } from "formik";
import axios from "axios";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { useState } from "react";
const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Email is Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  return errors;
};
const Contact = () => {
  const [response, setResponse] = useState();
  const formik = useFormik({
    initialValues: {
      email: "",
      message: "",
    },
    validate,
    onSubmit: (values) => {
      axios
        .post("https://kohinoortextile.com/api/contactus/create/", {
          email: values.email,
          message: values.message,
        })

        .then((res) => {
          setResponse(res.status);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  return (
    <>
      <NavBar />
      <SideBar title="Contact Us" />
      <DescriptionBox
        title="Contact Us"
        size="text-xl"
        description="We make fabrics for all kind of clothes. The manufacturing of the fabrics are done with different technologies . Modern machines and technologies are used in out industry hence making out fabrics of the best quality possible. We make fabrics for all kind of clothes. The manufacturing of the fabrics are done with different technologies . Modern machines and technologies are used in out industry hence making "
      />
      <section className="lg:max-w-3xl md:max-w-xl max-w-xs mx-auto pt-20">
        <form onSubmit={formik.handleSubmit}>
          <div className="pb-8">
            <input
              type="email"
              className="w-full lg:h-16 md:h-12 h-10 input-text pl-5"
              placeholder="Email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          </div>
          {formik.touched.email && formik.errors.email ? (
            <div className="text-red-600 pb-5">{formik.errors.email}</div>
          ) : null}
          <div className="pb-10">
            <textarea
              id="txtid"
              name="message"
              rows="4"
              cols="50"
              maxLength="400"
              className="w-full lg:h-40 md:h-32 input-text p-5 "
              placeholder="Message"
              value={formik.values.message}
              onChange={formik.handleChange}
            />
          </div>
          {response && response === 201 ? (
            <div>
              <h1 className="text-green-600 bold pb-10">
                Message Sent Successfully!!
              </h1>
            </div>
          ) : (
            ""
          )}
          <div className="pb-16">
            <button
              className="btn-primary px-16 py-3 capitalize mx-auto "
              type="submit"
            >
              Send Now
            </button>
          </div>
        </form>
        <div className="max-w-4xl pb-12 secondary-black font-semibold">
          <h1 className="lg:text-2xl md:text-xl ">
            Kohinoor Inc. Pvt. Ltd, Indrachowk, Kathmandu
          </h1>
          <div className="flex justify-center py-1">
            <IoCallOutline className="w-4 h-5" />
            <span className="pl-1"> +977-9841111110</span>
          </div>
        </div>
      </section>
      <div className="max-w-5xl pb-20 secondary-black font-semibold mx-auto">
        <h1 className="lg:text-2xl md:text-xl ">
          You can share your feedbacks and also contact through Email.
        </h1>
        <div className="flex justify-center py-1">
          <AiOutlineMail className="w-6 h-5" />
          <span className="pl-1">kohinoor@textile.com</span>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
