import SideBar from "./SideBar";
import "./style.css";
import { useEffect, useState } from "react";
import axios from "axios";
import DescriptionBox from "./DescriptionBox";
import NavBar from "./NavBar";
import Footer from "./Footer";

const GroupCompanies = () => {
  const [response, setResponse] = useState("");
  useEffect(() => {
    axios
      .get("https://kohinoortextile.com/api/quality/qualities-we-provide/")
      .then((res) => {
        setResponse(res.data);
      })
      .catch((error) => {
        console.log("the error ", error.response);
      });
  }, []);
  const imageSection = () => {
    return (
      <div>
        <div className="flex md:space-x-2 mx-auto pt-16 md:flex-row flex-col space-x-0 md:space-y-0 space-y-2 md:px-0 px-2">
          {response?.images?.map((img, index) =>
            index % 2 !== 0 ? (
              <div className="md:w-2/4 w-full">
                <img
                  src={img?.image}
                  alt=""
                  className="md:h-full md:w-full h-64 w-full"
                />
              </div>
            ) : (
              <div className="md:w-1/4 w-full ">
                <img
                  src={img?.image}
                  alt=""
                  className="md:h-full md:w-full h-64 w-full"
                />
              </div>
            )
          )}
        </div>
      </div>
    );
  };
  return (
    <>
      <NavBar />
      <section className="md:max-w-7xl w-full md:mx-auto mx-0 md:pb-16 pb-12">
        <SideBar title={response?.title} />
        <div className="">
          <img
            src={response?.image}
            alt=""
            width="100%"
            className="lg:h-auto md:h-72 h-44"
          />
        </div>
        <div>
          {response?.descriptions?.length > 0 &&
            response?.descriptions?.map((desc, index) =>
              index === 2 ? (
                <div>
                  {imageSection()}
                  <DescriptionBox
                    key={index}
                    title={desc?.desc_title}
                    color="font-teal "
                    size="text-2xl"
                    description={desc?.desc_text}
                  />
                </div>
              ) : (
                <DescriptionBox
                  key={index}
                  color="font-teal "
                  size="text-2xl"
                  title={desc?.desc_title}
                  description={desc?.desc_text}
                />
              )
            )}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default GroupCompanies;
