import "./style.css";
import SideBar from "./SideBar";
import DescriptionBox from "./DescriptionBox";
import axios from "axios";
import { useEffect, useState } from "react";
import Footer from "./Footer";
import NavBar from "./NavBar";

const GroupCompanies = () => {
  const [response, setResponse] = useState("");
  useEffect(() => {
    axios
      .get("https://kohinoortextile.com/api/productandtechnology/1/")
      .then((res) => {
        setResponse(res.data);
      });
  }, []);
  return (
    <>
      <NavBar />
      <section className="md:max-w-7xl w-full md:mx-auto mx-0 md:pb-16 pb-12">
        <SideBar title={response?.title1} />
        <div className="">
          <img
            src={response?.main_image}
            alt=""
            width="100%"
            className="lg:h-auto md:h-72 h-44"
          />
        </div>

        <DescriptionBox
          title={response?.title1}
          color="font-teal"
          size="md:text-2xl text-xl lg:pl-0 md:pl-10"
          description={response?.description1}
        />

        <DescriptionBox
          title={response?.title2}
          size="md:text-2xl text-xl"
          description={response?.description2}
        />
        <div>
          <div className="textile  flex mx-auto py-16 md:flex-row flex-col ">
            <div className="left md:w-1/2 w-full ">
              <h1 className="font-bold md:text-2xl text-xl  primary-black capitalize text-left md:text-center mx-2 md:mx-2">
                {response?.title3}
              </h1>
              <img
                src={response?.helper_image}
                alt=""
                className="md:pt-24 pt-5 mx-auto lg:w-96 md:w-80 w-full md:pb-0 pb-5"
              />
            </div>
            <div className="content-right  md:w-1/2 w-full text-left  primary-black md:px-0 px-2">
              <h1 className="pt-4 pb-2 primary-black font-semibold ">
                Principles of Fabric Designing
              </h1>
              <p className=" md:pl-0 pl-3">
                We make fabrics for all kind of clothes. The manufacturing of
                the fabrics are done with different technologies . Modern
                machines and technologies are used in out industry hence making
                out fabrics of the best quality possible. We make fabrics for
                all kind of clothes. The manufacturing of the done with
                different technologies.
              </p>
              <h1 className="pt-4 pb-2 primary-black font-semibold ">
                Engineering of Machines
              </h1>
              <p className=" md:pl-0 pl-3">
                We make fabrics for all kind of clothes. The manufacturing of
                the fabrics are done with different technologies . Modern
                machines and technologies are used in out industry hence making
                out fabrics of the best quality possible. We make fabrics for
                all kind of clothes. The manufacturing of the are done with
                different technologies.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default GroupCompanies;
