import "./App.css";
import GroupCompanies from "./components/GroupCompanies";
import Quality from "./components/Quality";
import Textile from "./components/Textile";
import Homepage from "./components/Homepage";
import ExportImport from "./components/ExportImport";
import ProductTech from "./components/ProductTech";
import CSR from "./components/CSR";
import Contact from "./components/Contact";
import AboutUs from "./components/AboutUs";
import OurTeam from "./components/OurTeam";
import Knittex from "./components/Knittex";
import Processing from "./components/Processing";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LandingPage from "./components/Landingpage";

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/about" component={AboutUs} />
          <Route path="/quality" component={Quality} />
          <Route path="/complaince" component={Homepage} />
          <Route path="/team" component={OurTeam} />
          <Route path="/textile" component={Textile} />
          <Route path="/knittex" component={Knittex} />
          <Route path="/processing" component={Processing} />
          <Route path="/exportimport" component={ExportImport} />
          <Route path="/groupcompanies" component={GroupCompanies} />
          <Route path="/csr" component={CSR} />
          <Route path="/product" component={ProductTech} />
          <Route path="/contact" component={Contact} />
          <Route path="/home" component={Homepage} />
          <Route path="/" exact component={LandingPage} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
