import React from "react";
import { Link } from "react-router-dom";
function DesktopMenu(props) {
    return (
        <section className={props.onTrigger ? "nav-menu active" : "nav-menu "}>
            <div className="row flex  mx-auto lg:space-x-2 md:space-x-2 space-x-6 lg:px-10 lg:py-2 md:px-3 md:py-0 px-0 py-0 my-5 ">
                <div className="column lg:space-y-3 md:space-y-2 space-y-6">
                    <div className="relative">
                        <Link to="/about" onClick={props.onHandle}>
                            <div className="img-bg-menu">
                                <h1 className="2xl:py-5 lg:py-2 md:py-1  md:text-xl py-8 font-bold lg:text-3xl uppercase primary-white ">
                                    About us
                                </h1>
                            </div>
                        </Link>
                        <img
                            src="https://kohinoortextile.com/static/media/images/menu/img1.png"
                            alt=""
                        />
                    </div>
                    <div className="relative">
                        <div className="img-bg-menu">
                            <div className="primary-white bottom-0 absolute w-full ">
                                <ul
                                    className="capitalize text-white menu-li xl:text-xl lg:text-base md:text-xs"
                                    onClick={props.onHandle}
                                >
                                    <Link to="/textile">
                                        {" "}
                                        <li className="2xl:p-3 lg:p-1 md:p-0 p-2">
                                            Kohinoor Textile
                                        </li>{" "}
                                    </Link>
                                    <Link to="/knittex">
                                        <li className="2xl:p-3 lg:p-1 p-2">
                                            Kohinoor Knittex
                                        </li>
                                    </Link>
                                    <Link to="/processing">
                                        <li className="2xl:p-3 lg:p-1 p-2">
                                            Kohinoor Processing
                                        </li>
                                    </Link>
                                    <Link to="/exportimport">
                                        <li className="2xl:p-3 lg:p-1 p-2">
                                            Kohinoor Export/Import
                                        </li>
                                    </Link>
                                </ul>
                                <h1 className="2xl:py-5 lg:py-2 md:py-1  md:text-xl py-8 font-bold lg:text-3xl uppercase primary-white ">
                                    our group companies
                                </h1>
                            </div>
                        </div>
                        <img
                            src="https://kohinoortextile.com/static/media/images/menu/img5.png"
                            alt=""
                        />
                    </div>
                </div>
                <div className="column lg:space-y-3 md:space-y-2 space-y-6">
                    <div className="relative">
                        <div className="img-bg-menu">
                            <h1 className="2xl:py-5 lg:py-2 md:py-1  md:text-xl py-8 font-bold lg:text-3xl uppercase primary-white ">
                                quality
                            </h1>
                            <ul
                                className="capitalize text-white menu-li xl:text-xl lg:text-base md:text-xs"
                                onClick={props.onHandle}
                            >
                                <Link to="/quality">
                                    <li className="2xl:p-3 lg:p-1 p-2">
                                        Shirting
                                    </li>
                                </Link>
                                <Link to="/quality">
                                    <li className="2xl:p-3 lg:p-1 p-2">
                                        Suiting
                                    </li>
                                </Link>
                                <Link to="/quality">
                                    <li className="2xl:p-3 lg:p-1 p-2">
                                        Cotton
                                    </li>
                                </Link>
                                <Link to="/quality">
                                    <li className="2xl:p-3 lg:p-1 p-2">
                                        Knitting
                                    </li>
                                </Link>
                                <Link to="/quality">
                                    <li className="2xl:p-3 lg:p-1 p-2">Net</li>
                                </Link>
                            </ul>
                        </div>

                        <img
                            src="https://kohinoortextile.com/static/media/images/menu/img2.png"
                            alt=""
                        />
                    </div>
                    <div className="relative">
                        <div className="img-bg-menu">
                            <div className=" bottom-0 absolute w-full ">
                                <ul
                                    className="capitalize text-white menu-li xl:text-xl lg:text-base md:text-xs"
                                    onClick={props.onHandle}
                                >
                                    <Link to="/csr">
                                        <li className="2xl:p-3 lg:p-1 p-2">
                                            society
                                        </li>
                                    </Link>
                                    <Link to="/csr">
                                        <li className="2xl:p-3 lg:p-1 p-2">
                                            sustainability
                                        </li>
                                    </Link>
                                </ul>
                                <h1 className="2xl:py-5 lg:py-2 md:py-1  md:text-xl py-8 font-bold lg:text-3xl uppercase primary-white ">
                                    csr
                                </h1>
                            </div>
                        </div>

                        <img
                            src="https://kohinoortextile.com/static/media/images/menu/img6.png"
                            alt=""
                        />
                    </div>
                </div>
                <div className="column lg:space-y-3 md:space-y-2 space-y-6 ">
                    <div className="relative">
                        <Link to="/complaince" onClick={props.onHandle}>
                            <div className="img-bg-menu">
                                <h1 className="2xl:py-5 lg:py-2 md:py-1  md:text-xl py-8 font-bold lg:text-3xl uppercase primary-white ">
                                    complaince
                                </h1>
                            </div>
                        </Link>

                        <img
                            src="https://kohinoortextile.com/static/media/images/menu/img3.png"
                            alt=""
                        />
                    </div>
                    <div className="relative">
                        <Link to="/product" onClick={props.onHandle}>
                            <div className="img-bg-menu">
                                <h1 className="2xl:py-5 lg:py-2 md:py-1  md:text-xl py-8 font-bold lg:text-3xl uppercase primary-white bottom-0 absolute w-full ">
                                    product and technology
                                </h1>
                            </div>
                        </Link>
                        <img
                            src="https://kohinoortextile.com/static/media/images/menu/img7.png"
                            alt=""
                        />
                    </div>
                </div>
                <div className="column lg:space-y-3 md:space-y-2 space-y-6">
                    <div className="relative">
                        <div className="img-bg-menu">
                            <h1 className="2xl:py-5 lg:py-2 md:py-1  md:text-xl py-8 font-bold lg:text-3xl uppercase primary-white  ">
                                Our team
                            </h1>
                            <ul
                                className="capitalize text-white menu-li xl:text-xl lg:text-base md:text-xs"
                                onClick={props.onHandle}
                            >
                                <Link to="/team">
                                    {" "}
                                    <li className="2xl:p-3 lg:p-1 p-2">
                                        Top management
                                    </li>
                                </Link>
                                <Link to="/team">
                                    <li className="2xl:p-3 lg:p-1 p-2">
                                        our team
                                    </li>
                                </Link>
                            </ul>
                        </div>

                        <img
                            src="https://kohinoortextile.com/static/media/images/menu/img4.png"
                            alt=""
                        />
                    </div>
                    <div className="relative">
                        <Link to="/contact" onClick={props.onHandle}>
                            <div className="img-bg-menu">
                                <h1 className="2xl:py-5 lg:py-2 md:py-1  md:text-xl py-8 font-bold lg:text-3xl uppercase primary-white  bottom-0 absolute w-full ">
                                    contact us
                                </h1>
                            </div>
                        </Link>
                        <img
                            src="https://kohinoortextile.com/static/media/images/menu/img8.png"
                            alt=""
                        />
                    </div>
                </div>

                <div className="button lg:pl-8 pl-2 primary-white">
                    <Link to="#" onClick={props.onHandle}>
                        <img
                            src="https://kohinoortextile.com/static/media/images/menu/vectormenu-cross.svg"
                            alt="menucross"
                            height="81.25px"
                            width="81.25px"
                        />
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default DesktopMenu;
