import React from "react";
import "./style.css";
import { useState, useEffect } from "react";
import axios from "axios";
function PhotoSection() {
  const [response, setResponse] = useState();
  useEffect(() => {
    axios
      .get("https://kohinoortextile.com/api/home/image/")
      .then((res) => {
        setResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <section className="lg:max-w-7xl mb-24 mx-auto lg:px-0 md:px-5 home-image-section md:max-w-full hidden  md:block">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
        {response &&
          response?.map((img) => (
            <div className="image-block">
              <div className="img-bg relative">
                <div className="home-imag">
                  <div className="absolute top-2/4 text-left px-10 pt-20  primary-white z-10">
                    <h1 className="capitalize font-bold text-2xl">
                      {img.title}
                    </h1>
                    <p className="text-sm opacity-80 pt-2">{img?.text}</p>
                  </div>
                </div>
                <img src={img?.image} alt="" className="h-full" />
              </div>
            </div>
          ))}
      </div>
    </section>
  );
}

export default PhotoSection;
