import GroupCompanies from "./GroupCompanies";
import axios from "axios";
import { useEffect, useState } from "react";

const Textile = () => {
  const [response, setResponse] = useState("");
  useEffect(() => {
    axios
      .get("https://kohinoortextile.com/api/groupcompany/kohinoor-textile/")
      .then((res) => {
        setResponse(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
      <GroupCompanies
        heading={response?.name}
        overview={response?.overview}
        description={response?.description}
        products={response?.products}
        bgImage={response?.image}
      />
    </>
  );
};

export default Textile;
